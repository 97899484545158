.cursor-pointer {
   cursor: pointer;
}



.search-select {
   position: relative;
}

.search-input {
   display: block;
   width: 100%;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: var(--bs-body-color);
   appearance: none;
   background-color: var(--bs-body-bg);
   background-clip: padding-box;
   border: var(--bs-border-width) solid var(--bs-border-color);
   border-radius: var(--bs-border-radius);
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

   &:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
   }
}

.options-list {
   position: absolute;
   top: 100%;
   left: 0;
   width: 100%;
   max-height: 170px;
   overflow-y: auto;
   border: 1px solid #ccc;
   border-top: none;
   border-radius: 0 0 4px 4px;
   background-color: #fff;
   z-index: 1;
}

.options-list li {
   padding: 8px;
   cursor: pointer;
}

.options-list li:hover {
   background-color: #f0f0f0;
}

.img {
   border: 2px solid var(--blue);
   border-radius: 100%;
   height: 9.3rem;
   width: 9.3rem;

   .pic-upload {
      border: 1px solid gray;
      height: 100%;
      width: 100%;
      border-radius: 100%;

      img {
         height: 100%;
         width: 100%;
         border-radius: 100%;
      }
   }
   .pic-upload {
      text-align: center;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-bottom: 20px;
      // margin-right: 1rem !important;
      .pic {
         height: 100%;
         width: 100%;
         -o-object-fit: cover;
         object-fit: cover;
         -o-object-position: center;
         object-position: center;
      }
      .upload-file-block,
      .upload-loader {
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         background-color: rgba(90, 92, 105, 0.7);
         color: #f8f9fc;
         font-size: 12px;
         font-weight: 600;
         opacity: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         transition: all 0.2s;
         &:hover{
            opacity: 1;
         }
      }
      .upload-file-block {
         cursor: pointer;
      }
   }
   
   .pic-upload:hover .upload-file-block {
      opacity: 1;
   }
   
   .pic-upload.uploadInProgress .upload-loader {
      opacity: 1;
   }
}

.about {
   .name {
      color: var(--secondary);
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0.3rem;
   }

   .education {
      margin-bottom: 0.5rem;
   }

   .data-row {
      display: flex;
      align-items: flex-start;
      gap: 3rem;

      .data {
         display: flex;
         align-items: flex-start;
         gap: 1.2rem;
         margin-bottom: 0.5rem;

         .title {
            font-weight: 700;
            white-space: nowrap;
            // width: 8rem;
         }

         .val {
            font-size: 0.9rem;
            max-width: 15rem;
         }
      }
   }
}











.h-full {
   height: 100%;
}

.h-10-r {
   height: 10rem;
}

.h-5-r {
   height: 5rem;
}

.h-8-r {
   height: 8rem;
}

.h-1-r {
   height: 1rem;
}

.w-1-r {
   width: 1rem;
}

.h-2-r {
   height: 2rem;
}

.w-2-r {
   width: 2rem;
}
.border-1-theme{
   border: 1px solid var(--theme-default);
}
.border-1-gray{
   border: 1px solid rgba(128, 128, 128, 0.678);
}
.w-30-r {
   width: 30rem;
}
.schedule-week{
   box-shadow: #63636333 0px 2px 8px 0px;
   margin-bottom: 1rem;
}
// .modal{
//    z-index: 11 !important;
//    .modal-backdrop{

//    }
// }
.align-center {
   align-items: center;
}

.w-25-r {
   width: 25rem;
}

.w-20-r {
   width: 20rem;
}

.w-15-r {
   width: 15rem;
}

.w-11-r {
   width: 11rem;
}

.w-10-r {
   width: 10rem;
}

.w-8-r {
   width: 8rem;
}

.w-7-r {
   width: 7rem;
}

.w-6-r {
   width: 6rem;
}

.w-5-r {
   width: 5rem;
}

.w-4-r {
   width: 4rem;
}

.w-3-r {
   width: 3rem;
}
.font-bold{
   font-weight: bold;
}
.font-1-r{
   font-size: 1.2rem;
}
.w-6-r {
   width: 6rem;
}

.zindex-999 {
   z-index: 999;
}

.align-center {
   align-items: center;
}

.justify-center {
   justify-content: center;
}

.justify-align-center {
   justify-content: center;
   align-items: center;
}

.bgLightBlack {
   background-color: #8b8888a1 !important;
}

.empty-container {
   height: 20rem;
   display: flex;
   justify-content: center;
   align-items: center;

   .img-container {
      height: 90%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .empty-title {
         padding: 10px 0px;
         font-size: 20px;
         font-weight: bold;
         position: absolute;
         bottom: 0;
      }

      img {
         height: 100%;
         width: 100%;
      }
   }
}

.tooltipCustom {
   position: absolute;
   z-index: 10;
   bottom: -43px;
   left: 50%;
   transform: translateX(-50%);
   width: max-content;
   max-width: max-content;
   background: rgb(27, 27, 27);
   border-radius: 5px;
   min-height: 30px;
   font-size: 0.75rem;
   color: white;
   display: none;
   align-items: center;
   justify-content: center;
   transition: all 0.4s linear;
   padding: 5px 8px;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
   text-align: center;

   &::before {
      position: absolute;
      top: -10px;
      left: 50%;
      content: "";
      display: block;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid gray;

   }
}

.action-icon {
   display: flex;
   transition: all 0.4s linear;
   position: relative;

   .tooltipCustom {
      left: auto;
      transform: translateX(0);
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      // display: block;
      right: 35px;

      &::before {
         left: auto;
         transform: translateX(0);
         top: 50%;
         right: -18px;
         bottom: auto;
         transform: translateY(-50%);
         border-bottom: none;
         border: 9px solid transparent;
         border-left-color: gray;
      }
   }

   img {
      height: 0.9rem !important;
      width: 0.9rem !important;
   }
}

.action-icon:hover {
   box-shadow: none;

   .tooltipCustom {
      transition: all 0.4s linear;
      display: flex !important;
   }
}

// Pagination
.custom-paginations {
   display: flex;
   justify-content: space-between;
   padding: 0 0.5rem 2rem 0;

   .right-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.4rem;

      .doted {
         font-weight: bold;
      }

      .page-name-button {
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 50%;
         // border: 1px solid blue;
         background-color: rgba(128, 128, 128, 0.568);
         color: black;
         height: 1.9rem;
         width: 1.9rem;
      }

      .active {
         background-color: blue;
         color: #ffffff;
      }

      button {
         width: 1.8rem;
         height: 1.8rem;
         border: none;
         cursor: pointer;
         border-radius: 50%;
      }

      .disabled {
         cursor: not-allowed;
         opacity: 0.4;
         display: flex;
         align-items: center;
         justify-content: center;
         background-color: #2323f8;
         width: 1.9rem;
         height: 1.9rem;
         border-radius: 50%;

         svg {
            color: white;
         }
      }

      .right-arr {
         display: flex;
         align-items: center;
         cursor: pointer;
         justify-content: center;
         background-color: #2323f8;
         width: 1.9rem;
         height: 1.9rem;
         border-radius: 50%;

         svg {
            color: white;
         }
      }

      .left-arr {
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;
         background-color: #2323f8;
         width: 1.9rem;
         height: 1.9rem;
         border-radius: 50%;

         svg {
            color: white;
         }
      }
   }
}